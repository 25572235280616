<template>
  <AuthWrapper>
    <div class="auth-contents">
      <div class="spin">
        <a-spin size="large" />
      </div>
    </div>
  </AuthWrapper>
</template>
<script>
import { FacebookOutlined, TwitterOutlined } from '@ant-design/icons-vue';
import { AuthWrapper } from './style';
import { computed, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

const Callback = {
  name: 'Line-Callback',
  components: { FacebookOutlined, TwitterOutlined, AuthWrapper },
  props: {
    query: Object,
  },
  setup(props) {
    const { state, dispatch } = useStore();

    const router = useRouter();
    const authCode = computed(() => state.line.authCode);
    const customToken = computed(() => state.line.customToken);

    const handleSignIn = () => {
      router.push('/');
    };

    const handleFbLogin = () => {
      dispatch('firebaseAuthLoginWithLine', customToken.value);
    };

    const handleLineLogin = () => {
      dispatch('lineAuthSignIn', authCode.value);
    };

    const handleLineSignUp = () => {
      router.push('/auth/line');
    };

    //เจอ authCode
    watch(
      () => state.line.authCode,
      authCode => {
        if (!authCode) return;
        handleLineLogin();
      },
    );

    //login กับ line และ krucare ผ่านจนได้ custom token
    watch(
      () => state.line.customToken,
      token => {
        if (!token) return;
        handleFbLogin();
      },
    );

    //ไม่เจอ user เลยต้องพาไปสมัครสมาชิก
    watch(
      () => state.line.needSignUp,
      needSignUp => {
        if (!needSignUp) return;
        handleLineSignUp();
      },
    );

    //login กับ firebase ผ่านได้ uid มาแล้ว
    watch(
      () => state.firebase.uid,
      async uid => {
        if (!uid) return;
        await dispatch('userGetInfos');
        await dispatch('schoolInfoGetData');
        handleSignIn();
      },
    );

    onMounted(() => {
      //เริ่มจากส่ง auth code
      dispatch('lineAuthCallbackBegin', props.query.code);
    });

    return {};
  },
};

export default Callback;
</script>
